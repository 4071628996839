"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.default = void 0;

var _UnimplementedView = _interopRequireDefault(require("../../modules/UnimplementedView"));

var _default = _UnimplementedView.default;
exports.default = _default;
module.exports = exports.default;