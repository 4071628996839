"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.default = void 0;

var _PanResponder = _interopRequireDefault(require("../../vendor/react-native/PanResponder"));

var _default = _PanResponder.default;
exports.default = _default;
module.exports = exports.default;